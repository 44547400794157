@import 'color';

.drawer {
  .closeBtn {
    position: absolute;
    top: 0;
    left: -30px;
    padding: 12px 8px;
    border-radius: 0px 0px 0px 4px;
    background: $iBuzzWhite;
  }
  .contentContainer  {
    height: 100%;
    overflow: auto;
    padding: 32px 40px;
    .titleRow {
      display: flex;
      align-items: center;
      column-gap: 9px;
      margin-bottom: 20px;
      &.top20 {
        margin-top: 20px;
      }
      .titleText {
        font-size: 20px;
        font-weight: 700;
        color: $iBuzzBlue;
        line-height: 1.2;
      }
    }
    section {
      .sectionTitle {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-bottom: 20px;
        &.top20 {
          margin-top: 20px;
        }
        .titleText {
          font-size: 16px;
          font-weight: 700;
          line-height: 1;
        }
      }
      .block {
        padding: 20px 0px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        border-bottom: 1px solid $commonGrey;
        .row {
          display: grid;
          grid-template-columns: 1fr 4fr;
          grid-gap: 28px;
          align-items: start;
          .columnTitle {
            font-size: 14px;
            font-weight: 400;
            margin: 0px 0px 0px auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            line-height: 22px;
            div {
              &:first-child {
                font-weight: 700;
              }
              &:nth-child(2n) {
                font-size: 12px;
                color: $iBuzzDeepGrey;
              }
            }
            &.error {
              color: $iBuzzError;
            }
          }
          .rowTitle {
            position: relative;
            font-size: 14px;
            line-height: 44px;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 8px;
            white-space: pre-wrap;
            &.error {
              color: $iBuzzError;
            }

            .changeNotice {
              position: absolute;
              bottom: 0;
              right: 0;
              font-size: 12px;
              color: $iBuzzError;
              translate: 10px 20px;
            }
          }
          .rowContent {
            position: relative;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 8px;
            &.autoContent {
              grid-template-columns: 1fr auto;
            }
            &.aiComment {
              grid-template-columns: auto 1fr;
            }
            .input {
              height: 44px;
              border-color: $iBuzzDeepGrey;
              &.full {
                grid-area: 1/1/2/3;
              }
              &.error {
                border-color: $iBuzzError;
                &::placeholder {
                  color: $iBuzzError;
                }
              }
            }
            .select {
              height: 44px;
              &>div {
                border-color: $iBuzzDeepGrey;
                &>span {
                  color: $iBuzzBlack;
                }
              }
              &.disabled {
                opacity: 0.4;
                &>div {
                  background: #fff;
                }
              }
              &.full {
                grid-area: 1/1/2/3;
              }
              &.error {
                &>div {
                  border-color: $iBuzzError;
                  &>span {
                    color: $iBuzzError;
                  }
                }
              }
            }
            .switch {
              margin: 11px 0px;
              width: 44px;
            }
            .datePicker {
              height: 44px;
              border-color: $iBuzzDeepGrey;
              background: #fff;
              input::placeholder {
                color: $iBuzzBlack;
              }
              &.disabled {
                opacity: 0.4;
              }
              &.full {
                grid-area: 1/1/2/3;
              }
              &.error {
                border-color: $iBuzzError;
                ::placeholder {
                  color: $iBuzzError;
                }
              }
            }
            .errorText {
              position: absolute;
              bottom: -20px;
              font-size: 12px;
              line-height: 20px;
              font-weight: 400;
              color: $iBuzzError
            }
            .desc {
              display: flex;
              align-items: center;
              column-gap: 4px;
              font-size: 14px;
              .descText {
                font-size: 12px;
                font-weight: 400;
                line-height: 44px;
                color: $iBuzzDeepGrey
              }
              &.aiCommentDesc {
                font-size: 12px;
                color: $iBuzzError;
              }
            }
            &>.tagContainer {
              border: 1px solid $iBuzzDeepGrey;
              border-radius: 8px;
              padding: 8px;
              grid-area: 1/1/2/3;
              display: flex;
              flex-wrap: wrap;
              row-gap: 8px;
              column-gap: 8px;
              &.noBorder {
                bottom: none;
              }
              .tagStyle {
                padding: 6px 8px;
                line-height: 1;
                margin-right: 0px;
                border: none;
                background: $iBuzzGrey;
                display: flex;
                align-items: center;
                height: min-content;
              }
            }
            .dummyContainer {
              grid-area: 1/1/2/3;
              min-height: 148px;
              border: 1px solid $iBuzzDeepGrey;
              border-radius: 8px;
              &.error {
                border-color: $iBuzzError;
              }
              .tagContainer {
                padding: 8px;
                display: flex;
                flex-wrap: wrap;
                row-gap: 8px;
                column-gap: 8px;
                .tagStyle {
                  padding: 6px 8px;
                  line-height: 1;
                  margin-right: 0px;
                  border: none;
                  background: $iBuzzGrey;
                  display: flex;
                  align-items: center;
                  height: min-content;
                }
                .emptyNote {
                  line-height: 28px;
                  color: $iBuzzDeepGrey;
                  &.error {
                    color: $iBuzzError
                  }
                }
              }
            }
          }
          .autoSendRow {
            position: relative;
            display: grid;
            grid-template-columns: auto 1fr;
            grid-gap: 16px;
            margin: auto 0px;
            .toadyOptions {
              display: flex;
              align-items: center;
              column-gap: 16px;
              .option {
                display: flex;
                align-items: center;
                column-gap: 4px;
              }
            }
          }
        }
        .buttonContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          .addBtn {
            width: 160px;
            height: unset;
            padding: 14px 15px;
          }
        }
      }
    }
  }
  .drawerFooter {
    padding: 12px 0px;
    margin: 0px 28px;
    border-top: 1px solid $iBuzzGrey;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}